import smoothscroll from 'smoothscroll-polyfill';

// main
import {
    Menu,
    ScrollManager,
    AnchorScroll,
    Accordion,
    ImageParallax,
    ElementShuffle,
} from './_main';

// home
import {
    OpeningAnimation,
    ListSlider,
    ScrollTriggerClass,
    ModalVideo,
} from './_home'

// places
import {
    PlacesGallery,
    AreaFiltering,
} from './_places'

// dress
import {
    DressVisual
} from './_dress'

// faq
import {
    TabFiltering
} from './_faq'

{
    smoothscroll.polyfill();

    const domMenu = document.querySelector('[data-js="trigger"]');
    if (domMenu) new Menu(domMenu);

    const domScroller = document.querySelector('[data-scroller]');
    if (domScroller) new ScrollManager(domScroller);

    const domAnchorScroll = [...document.querySelectorAll('a[href^="#"]')];
    if (domAnchorScroll.length !== 0) {
        domAnchorScroll.forEach(el => {
            new AnchorScroll(el);
        });
    }

    const domAccordion = document.querySelectorAll('[data-js="accordion"]');
    if (domAccordion.length !== 0) {
        [...domAccordion].forEach(el=>{
            new Accordion(el, 'acd-trigger', 'acd-container');
        })
    }
    const domAccordionCategory = document.querySelectorAll('[data-js="accordion-category"]');
    if (domAccordionCategory.length !== 0) {
        [...domAccordionCategory].forEach(el=>{
            new Accordion(el, 'category-trigger', 'category-container');
        })
    }
    const domAccordionQa = document.querySelectorAll('[data-js="accordion-qa"]');
    if (domAccordionQa.length !== 0) {
        [...domAccordionQa].forEach(el=>{
            new Accordion(el, 'qa-trigger', 'qa-container');
        })
    }

    const domImageParallax = document.querySelectorAll('[data-js="image-parallax"]');
    if (domImageParallax) new ImageParallax(domImageParallax);
}

// home
{
    const domOpeningAnimation = document.querySelector('[data-js="opening"]');
    if (domOpeningAnimation) new OpeningAnimation(domOpeningAnimation);

    const domScrollTriggerClass = document.querySelectorAll('[data-trigger="stc"]');
    if (domScrollTriggerClass) new ScrollTriggerClass(domScrollTriggerClass);  

    const domListSliderPlaces = document.querySelector('[data-js="slider-places"]');
    if (domListSliderPlaces) new ListSlider(domListSliderPlaces);
    const domListSliderService = document.querySelector('[data-js="slider-service"]');
    if (domListSliderService) new ListSlider(domListSliderService);
    const domListSliderDress = document.querySelector('[data-js="slider-dress"]');
    if (domListSliderDress) new ListSlider(domListSliderDress);

    const domModalVideos = document.querySelectorAll('[data-js="modal-trigger"]');
    if (domModalVideos.length !== 0) {
        new ModalVideo(domModalVideos);
    }
}

// places
{
    const domAreaFiltering = document.querySelector('[data-js="filter"]');
    if (domAreaFiltering) new AreaFiltering(domAreaFiltering);

    const domPlacesGallery = document.querySelectorAll('[data-js="places-gallery"]');
    if (domPlacesGallery.length !== 0) new PlacesGallery(domPlacesGallery);
}

// dress
{
    // const domDressGallery = document.querySelectorAll('[data-js="dress-gallery"]');
    // if (domDressGallery.length !== 0) new DressGallery(domDressGallery);

    const domDressVisual = document.querySelector('[data-js="dress-visual"]');
    if (domDressVisual) new DressVisual(domDressVisual);

    const domElementShuffle = document.querySelectorAll('[data-js="shuffle"]');
    // console.log(domElementShuffle)
    if (domElementShuffle) {
        [...domElementShuffle].forEach(el => {
            new ElementShuffle(el, 'drs-contents__units');
        })
    }

}

// faq
{
    const domTabFiltering = document.querySelectorAll('[data-js="tab"]');
    if (domTabFiltering.length !== 0) new TabFiltering(domTabFiltering);
}
