import KeenSlider from 'keen-slider'
import 'keen-slider/keen-slider.min.css'

class DressVisual {
  constructor(el) {
    this.dom = {el: el};

    this.setNavigation = this.setNavigation.bind(this);
    this.mql = window.matchMedia('screen and (min-width: 768px)');
    this.checkBreakPoint = this.checkBreakPoint.bind(this);

    this.slider = null;
    this.option = {
      loop: true,
      slides: {
        origin: 'center',
        perView: 375 / 316,
      },
      breakpoints: {
        '(min-width: 768px)': {
          slides: {
            origin: 'center',
            perView: 1366 / 512,
          },
        },
      },
      // created: () => {
      //   console.log('created')
      // }
    }

    this.init();
  }

  init() {
    this.slider = new KeenSlider(this.dom.el, this.option, [this.setNavigation]);
    
    // // ブレイクポイントの瞬間に発火
    // this.mql.addListener(this.checkBreakPoint);
    // // 初回チェック
    // this.checkBreakPoint(this.mql);
  }

  setNavigation(slider) {
    let wrapper, dots, arrowLeft, arrowRight
  
    function markup(remove) {
      wrapperMarkup(remove)
      // dotMarkup(remove)
      arrowMarkup(remove)
    }
  
    function removeElement(elment) {
      elment.parentNode.removeChild(elment)
    }
    function createDiv(className) {
      const regex = new RegExp('arrow*');
      let div = document.createElement('div')
      let classNames = className.split(' ')
      classNames.forEach((name) => div.classList.add(name))
      if (regex.test(className)) {
        div.innerHTML = (window.innerWidth < 768) ? '<svg viewBox="0 0 20 38"><use xlink:href="#arrow-slide"></use></svg>' : '<svg viewBox="0 0 35 65"><use xlink:href="#arrow-slide-thin"></use></svg>';
      }
      return div
    }
  
    function arrowMarkup(remove) {
      if (remove) {
        removeElement(arrowLeft)
        removeElement(arrowRight)
        return
      }
      arrowLeft = createDiv('arrow arrow--left')
      arrowLeft.addEventListener('click', () => slider.prev())
      arrowRight = createDiv('arrow arrow--right')
      arrowRight.addEventListener('click', () => slider.next())
  
      wrapper.appendChild(arrowLeft)
      wrapper.appendChild(arrowRight)
    }
  
    function wrapperMarkup(remove) {
      if (remove) {
        let parent = wrapper.parentNode
        while (wrapper.firstChild)
          parent.insertBefore(wrapper.firstChild, wrapper)
        removeElement(wrapper)
        return
      }
      wrapper = createDiv('navigation-wrapper')
      slider.container.parentNode.appendChild(wrapper)
      wrapper.appendChild(slider.container)
    }
  
    function dotMarkup(remove) {
      if (remove) {
        removeElement(dots)
        return
      }
      dots = createDiv('dots')
      slider.track.details.slides.forEach((_e, idx) => {
        let dot = createDiv('dot')
        dot.addEventListener('click', () => slider.moveToIdx(idx))
        dots.appendChild(dot)
      })
      wrapper.appendChild(dots)
    }
  
    function updateClasses() {
      let slide = slider.track.details.rel
      slide === 0
        ? arrowLeft.classList.add('arrow--disabled')
        : arrowLeft.classList.remove('arrow--disabled')
      slide === slider.track.details.slides.length - 1
        ? arrowRight.classList.add('arrow--disabled')
        : arrowRight.classList.remove('arrow--disabled')
      // Array.from(dots.children).forEach(function (dot, idx) {
      //   idx === slide
      //     ? dot.classList.add('dot--active')
      //     : dot.classList.remove('dot--active')
      // })
    }
  
    slider.on('created', () => {
      markup()
      updateClasses()
    })
    slider.on('optionsChanged', () => {
      console.log(2)
      markup(true)
      markup()
      updateClasses()
    })
    slider.on('slideChanged', () => {
      updateClasses()
    })
    slider.on('destroyed', () => {
      markup(true)
    })
  }  

  checkBreakPoint(_mql) {
		if (!_mql.matches) {
      // console.log('sp');
      // if (this.slider === null) {
      //   this.slider = new KeenSlider(this.dom.el, this.option, []);
      // }
      return;
    } else {
      // console.log('pc');
      // if (this.slider !== null && this.dataJs === 'slider-service') {
      //   this.slider.destroy();
      //   this.slider = null;
      // } else if (this.slider === null && this.dataJs !== 'slider-service') {
      //   this.slider = new KeenSlider(this.dom.el, this.option, []);
      // }
      return;
		}
	}
}

export default DressVisual